<template>

  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader :title="$t('purchaseCheques.purchaseCheques')"
                     :description="$t('purchaseCheques.fromHereYouCanControlThepurchaseCheques')"/>
         <indexTable :searchText="$t('purchaseCheques.FindACheck')"
                     :emptyTableText="$t('purchaseCheques.thereAreNopurchaseCheques')"
                     :emptyTableSubText="$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain')"
                     :buttonRole="$user.role.purchase_cheques_add"
                     :statusSearch="true"
                     :statusValues="[
                        { title: $t('purchaseCheques.active'), value: '' },
                        { title: $t('purchaseCheques.Waiting'), value: 0 },
                        { title: $t('purchaseCheques.driven'), value: 1 },
                      ]"
                     :cloumns="[
                         {column: 'code',    title: $t('purchaseCheques.batch') ,type: 'mainLink' , sort: true },
                         {column: 'client_type',    title: $t('purchaseCheques.supplierName') ,type: 'clintLink', sort: true},
                         {column: 'safe_id',    title:$t('purchaseCheques.Treasury'),      type: 'link', to:'safe', sort: true, link: true},
                         {column: 'created_date',    title: $t('purchaseCheques.RegistrationDate') ,type: 'text' , sort: true },
                         {column: 'date',    title: $t('purchaseCheques.paymentDate') ,type: 'text' , sort: true },
                         {column: 'description',    title: $t('purchaseCheques.thatsAbout') ,type: 'text' , sort: true },
                         {column: 'cost',    title: $t('purchaseCheques.amount') ,type: 'text' , sort: true },
                         {column: 'payment_method_id',    title: $t('purchaseCheques.PaymentMethod') ,type: 'link', to:'paymentMethod', sort: true, link: false},
                         {column: 'stat',    title: $t('purchaseCheques.active') ,type: 'status', sort: true,  values: [
                           {title: $t('purchaseCheques.Waiting'), value: 0, color: 'info'},
                           {title: $t('purchaseCheques.driven'), value: 1, color: 'success'},
                           {title: $t('purchaseCheques.refused'), value: 2, color: 'danger'}
                         ]},
                         {column: 'options', title: $t('purchaseCheques.settings'), type: 'options', options: [
                             {name: 'show'},
                             {name: 'printPurchaseCheque',   role: true},
                             {name: 'edit',   role: $user.admin || $user.role.purchase_cheques_edit},
                             {name: 'file'},
                             {name: 'delete', role: $user.admin || $user.role.purchase_cheques_delete},
                         ]}
                       ]"
                     :deleteText="{
                        attention: $t('allerts.Attention'),
                        areYouReallySureToDeleteTheItem: $t('allerts.areYouReallySureToDeleteTheItem'),
                        close: $t('allerts.close'),
                        confirm: $t('allerts.confirm'),
                        loading: $t('allerts.loading'),
                       }" />
      </div>
    </div>
  </div>

</template>
<script>


  import indexHeader from '../elements/index/indexHeader.vue'
  import indexTable from '../elements/index/indexTable.vue'

  export default {
    data() {
      return {
        path: '/purchaseCheques',
      }
    },
    components: {
      indexHeader,
      indexTable,
    }
  }

</script>
